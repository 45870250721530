// ATTENTION: changing reasons name can affect project's Sentry alerts

export enum ERROR_REASONS {
    // auth
    permission = 'permission',
    login = 'login',
    getAccessToken = 'getAccessToken',
    tokenRefresh = 'tokenRefresh',
    tokenRefreshFailed = 'tokenRefreshFailed',
    session = 'session',

    // user
    updatingUser = 'updatingUser',
    userEmail = 'userEmail',
    getUser = 'getUser',
    getUserList = 'getUserList',
    getUserParams = 'getUserParams',

    // localization
    creatingLocalizationKey = 'creatingLocalizationKey',
    fetchLocalization = 'fetchLocalization',
    fetchLocalizationKey = 'fetchLocalizationKey',
    fetchLocalizationRecord = 'fetchLocalizationRecord',
    localizationSync = 'localizationSync',
    localizationDelete = 'localizationDelete',

    // featureSets
    fetchFeatureSet = 'fetchFeatureSet',

    // templates
    getSelectedTemplates = 'getSelectedTemplates',
    fetchTemplatesList = 'fetchTemplatesList',
    fetchTemplate = 'fetchTemplate',
    createTemplate = 'createTemplate',
    updatingTemplate = 'updatingTemplate',
    fetchTemplatesGrid = 'fetchTemplatesGrid',
    getTemplatesById = 'getTemplatesById',

    // screens
    creatingScreen = 'creatingScreen',
    updatingScreen = 'updatingScreen',
    getScreenById = 'getScreenById',
    getScreenByListId = 'getScreenByListId',
    getSelectedScreens = 'getSelectedScreens',
    getScreensList = 'getScreensList',
    deleteScreen = 'deleteScreen',
    fetchScreenAutocomplete = 'fetchScreenAutocomplete',
    autocompleteError = 'autocompleteError',
    getScreenTranslations = 'getScreenTranslations',
    filterAutocomplete = 'filterAutocomplete',
    screenPreviewReformatConfig = 'screenPreviewReformatConfig',

    // links
    getLinks = 'getLinks',
    getLinkById = 'getLinkById',
    getSelectedLinks = 'getSelectedLinks',
    creatingLinks = 'creatingLinks',
    updatingLinks = 'updatingLinks',
    deletingLink = 'deletingLink',

    // flows
    updateFlow = 'updateFlow',
    getFlow = 'getFlow',

    // branches
    getBranchById = 'getBranchById',
    getBranchList = 'getBranchList',
    getSelectedBranches = 'getSelectedBranches',
    creatingBranch = 'creatingBranch',
    updatingBranch = 'updatingBranch',
    deletingBranch = 'deletingBranch',

    // products
    getProductCodeById = 'getProductCodeById',
    getProductCodeList = 'getProductCodeList',
    createProductCode = 'createProductCode',
    updateProductCode = 'updateProductCode',
    deletingProduct = 'deletingProduct',
    fetchingProduct = 'fetchingProduct',
    fetchingProducts = 'fetchingProducts',
    fetchingProductCodes = 'fetchingProductCodes',

    // productPlan
    fetchingProductPlan = 'fetchingProductPlan',
    creatingProductPlan = 'creatingProductPlan',
    updatingProductPlan = 'updatingProductPlan',
    activatingProductPlan = 'activatingProductPlan',
    existedProductPlan = 'existedProductPlan',

    // projects
    creatingProject = 'creatingProject',
    updatingProject = 'updatingProject',
    fetchingProject = 'fetchingProject',
    fetchProjects = 'fetchProjects',

    // images
    uploadingImages = 'uploadingImages',
    getImages = 'getImages',

    // themes
    fetchSelectedThemes = 'fetchSelectedThemes',
    createThemes = 'createThemes',
    fetchingThemes = 'fetchingThemes',

    // ltv
    ltvCoefficientSync = 'ltvCoefficientSync',

    // other
    general = 'general',
    axios = 'axios',
    amplify = 'amplify',
    rebillRates = 'rebillRates',
    fetchCurrencies = 'fetchCurrencies',
    fetchCountries = 'fetchCountries',
    fetchPreset = 'fetchPreset',
    fetchVAT = 'fetchVAT',
    auditLogs = 'auditLogs',
}
